import React from "react";
import styles from "./NotificationElement.module.scss";
import cn from "classnames";
import { INotificationElement } from "./types";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBasketShopping,
  faCircleExclamation,
  faCircleInfo,
  faMessage,
  faRankingStar,
  faShoePrints,
  faTriangleExclamation,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import { INotificationTypeEnum } from "api/openApi";
import {
  faHand,
  faPenToSquare,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";

const NotificationElement = (props: INotificationElement) => {
  const {
    notification: { title, body, createdAt, seen, type },
    onNotificationClick,
  } = props;

  const onClick = () => {
    if (onNotificationClick) onNotificationClick(props.notification);
  };

  const getNotificationIcon = () => {
    switch (props.notification.type) {
      case INotificationTypeEnum.NewMessage:
        return faMessage;
      case INotificationTypeEnum.OrderUpdates:
        return faBasketShopping;
      case INotificationTypeEnum.Subscription:
        return faUserClock;
      case INotificationTypeEnum.ProductApproved:
        return faThumbsUp;
      case INotificationTypeEnum.ProductRejected:
        return faThumbsDown;
      case INotificationTypeEnum.ProductReported:
        return faHand;
      case INotificationTypeEnum.NewProductsOnSneaker:
      case INotificationTypeEnum.NewSneaker:
        return faShoePrints;
      case INotificationTypeEnum.StoreReviewUpdates:
        return faRankingStar;
      case INotificationTypeEnum.AddStoreReview:
        return faPenToSquare;
      case INotificationTypeEnum.AdminProductPending:
      case INotificationTypeEnum.AdminProductReported:
        return faTriangleExclamation;
      case INotificationTypeEnum.Info:
        return faCircleInfo;
      case INotificationTypeEnum.Error:
        return faCircleExclamation;
    }
  };

  const admin =
    type === INotificationTypeEnum.AdminProductPending ||
    type === INotificationTypeEnum.AdminProductReported;

  return (
    <li
      onClick={onClick}
      className={cn(styles["notification"], {
        [styles["notification--seen"] as string]: seen,
        [styles["notification--admin"] as string]: admin,
      })}
    >
      <div className={styles["notification__top"]}>
        <FontAwesomeIcon height={16} icon={getNotificationIcon()} />
        {!seen && <div className={styles["notification__top-seen"]} />}
        <div className={styles["notification__top-title"]}>{title}</div>
        <div className={styles["notification__top-date"]}>
          {moment(createdAt).format("DD/MM/YY")}
        </div>
      </div>
      <div
        className={styles["notification__description"]}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </li>
  );
};

export default NotificationElement;
