import TextInput from "components/common/atoms/TextInput";
import style from "./index.module.scss";
import { useContext, useState } from "react";
import Button from "components/common/atoms/Button";
import axios from "axios";
import { GeneralContext } from "contexts/GeneralContext";
import { validateEmail } from "helpers/functions";

const hubspotApi =
  process.env.REACT_APP_HUBSPOT_API || process.env.NEXT_PUBLIC_HUBSPOT_API;

const WaitList = () => {
  const [email, setEmail] = useState("");
  const [messageSend, setMessageSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { t } = useContext(GeneralContext);

  const onChange = (value: string) => {
    setEmail(value);
  };

  const onClick = async () => {
    setIsLoading(true);
    let localError = "";
    if (email === "") {
      localError = t("errors.requiredField");
    } else if (!validateEmail(email)) {
      localError = t("errors.invalidEmail");
    }

    if (localError.length > 0) {
      setError(localError);
    } else {
      const body = {
        sumbittedAt: new Date().getTime(),
        fields: [
          {
            name: "email",
            value: email,
          },
        ],
        context: {
          pageUri: window.location.href,
          pageName: "Waitlist",
        },
      };
      try {
        const response = await axios({
          method: "post",
          url: hubspotApi,
          data: body,
        });
        if (response.status === 200) {
          setIsLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
      setMessageSend(true);
    }
    setIsLoading(false);

  };

  return (
    <div className={style["waitlist"]}>
      {messageSend ? (
        <div className={style["waitlist__send"]}>
          Thanks for subscribing! <br /> Stay tuned—something exciting is coming
          soon!
        </div>
      ) : (
        <>
          <div className={style["waitlist__title"]}>Join the waitlist!</div>
          <div className={style["waitlist__input"]}>
            <TextInput
              label="We will notify you on this email"
              onChange={onChange}
              value={email}
              className={style["waitlist__input-text"]}
              hasBorder
              error={error}
            />
            <Button
              onClick={onClick}
              disabled={isLoading}
              type="primary"
              className={style["waitlist__input-button"]}
            >
              Subscribe
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default WaitList;
